* {
  font-family: 'Happy Monkey', sans-serif;
}

.token-distribution {
  text-align: center;
  padding: 20px;
  background-color: #F2DBAE;
}

.token-distribution h2 {
  margin-bottom: 20px;
}

.token-distribution .token-pie {
  width: 80%; 
  max-width: 375px; 
  margin: 0 auto; 
  aspect-ratio: 1;
}

@media (min-width: 768px) {
  .token-distribution .token-pie {
    max-width: 444px;
  }
}

.token-pie {
  padding-bottom: 50px;
}