* {
  font-family: 'Happy Monkey', sans-serif;
}

.footer {
    text-align: center;
    padding: 5px;
    background-color: #260101;
    color: #F2DBAE;
    /* Add more styles */
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  line-break: anywhere;
}