.language-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .language-selector button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .language-selector button img {
    width: 32px; /* Or your preferred size */
    height: auto;
  }
  