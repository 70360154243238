.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Adjusted padding */
  background-color: #2f2fb9;
  width: 100%; /* Ensure header is full width */
  box-sizing: border-box; /* Ensures padding is included in width */
  position: sticky; /* Sticky position */
  top: 0; /* Stick to the top */
  z-index: 1000; /* Ensure it's above other content */
}
  
  .header img {
    height: 88px;
  }
  
  .header nav a {
    margin-left: 44px;
    text-decoration: none;
    color: #F2DBAE;
  }

  .header nav a:hover {
    color: #BF3030;
  }
  
  @keyframes wiggle {
    0%, 100% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
  }

  @keyframes wiggleHard {
    0%, 100% { transform: rotate(-10deg); }
    50% { transform: rotate(10deg); }
  }
  

  .header-logo {
    animation: wiggle 2s ease-in-out;
  }
  
  .header-logo:hover {
    animation: wiggleHard 1s ease-in-out infinite;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .hamburger {
    position: absolute;
    display: none;
  }

  .language-selector {
    display: flex;
    align-items: center;
    /* Other styles... */
  }

  .language-selector button {
    background: none;
    border: none;
    margin: -5px 10px 0px 44px; /* Adjust spacing as needed */
    padding: 0;
    cursor: pointer;
  }

  .hamburger, .close-icon {
    cursor: pointer;
    z-index: 1001; /* Ensure it's above other content */
    color: #F2DBAE;
  }
  
  .close-icon {
    display: none; /* Hide by default, will be toggled by JS */
    position: fixed; /* Use fixed to keep it on top of all content */
    top: 20px;
    right: 20px;
    font-size: 1.5rem;
    color: #F2DBAE;
  }
  
  .nav-items {
    display: flex;
  }
  
  @media (max-width: 768px) {
    .header {
      justify-content: center; /* Center the logo */
      position: sticky; /* Sticky header */
    }

    .hamburger {
      display: block; /* Show hamburger icon */
      position: absolute; /* Absolute position to move it to the right */
      right: 34px; /* Right alignment */
      top: 34px; /* Adjust top alignment if necessary */
    }
  
    .close-icon {
      display: block; /* Show the close icon */
      position: fixed; /* Use fixed to keep it on top of all content */
      top: 34px;
      right: 34px;
      z-index: 1001; /* Make sure it's on top */
    }
  
    .nav-items {
      display: none; /* Hide nav items initially */
      position: fixed; /* Stick to the top */
      top: 0;
      left: 0;
      flex-direction: column; /* Stack items vertically */
      justify-content: center; /* Center items vertically */
      align-items: center; /* Center items horizontally */
      width: 100%; /* Full width */
      background-color: #2f2fb9; /* Match header background */
      transition: all 0.3s ease;
      transform: translateY(-100%); /* Start off-screen */
      opacity: 0;
      z-index: 1000; /* Lower than close icon */
      padding-top: 60px; /* Adjust based on header size */
      padding-bottom: 20px; /* Adjust as needed */
    }
  
    .nav-items.open {
      display: flex; /* Show nav items */
      transform: translateY(0); /* Bring into view */
      opacity: 1; /* Make fully visible */
      height: calc(100vh); /* Adjust height to remove bottom space */
    }
  
    .nav-items a {
      font-size: 1.5rem; /* Larger font size for readability */
      padding: 20px 0; /* More padding for tap targets */
      width: 100%; /* Full width to create column effect */
      text-align: center; /* Center text */
      border-bottom: 1px solid #F2DBAE; /* Separate items */
    }
  
    .nav-items a:last-child {
      border-bottom: none; /* No border for the last item */
    }
  
    .hidden {
      display: none; /* Hide when not needed */
    }

    .language-selector button {
      background: none;
      border: none;
      margin-top: 44px; /* Adjust spacing as needed */
      padding: 0;
      cursor: pointer;
    }
  }
  
  