* {
  font-family: 'Happy Monkey', sans-serif;
}

.roadmap {
  text-align: center;
  padding: 20px;
  background-color: #F2DBAE;
}

.roadmap h2 {
  margin-bottom: 20px;
}

.milestones {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.milestone {
  background-color: #D98943;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px #260101;
  width: 80%;
  max-width: 500px;
}

.milestone.completed {
  border-left: 5px solid #2f2fb9;
}

.milestone.todo {
  border-left: 5px solid #BF3030;
  opacity: 0.7;
}

.milestone h3 {
  color: #260101;
  margin-bottom: 10px;
}

.milestone span {
  font-style: italic;
}
