/*
Light   #F2DBAE
Brown   #D98943
Orange  #F25E3D
Dark    #260101
Red     #BF3030
Blue    #2f2fb9
*/

.App {
  text-align: center;
  width: 100%;
}