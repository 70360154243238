button {
    background-color: transparent;
    border: none;
    color: #F2DBAE;
}

@keyframes wiggle {
    0%, 100% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
  }

button:hover {
    animation: wiggleHard 0.5s ease-in-out infinite;
}