@import url('https://fonts.googleapis.com/css?family=Happy+Monkey');

* {
  font-family: 'Happy Monkey', sans-serif;
}

.main-content {
  text-align: center;
  padding: 100px;
  background-color: #F2DBAE;
}

.main-content .logo {
  width: 150px;
  margin-bottom: 20px;
  animation: bounce 2s infinite;
}

.main-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.main-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.join-button {
  background-color: #2f2fb9;
  color: #F25E3D;
  padding: 10px 20px;
  font-family: 'Happy Monkey', sans-serif;
  font-weight: bold;
  border-radius: 10px 9px 18px 7px;
  margin: 20px;
}

@keyframes wiggle {
  0%, 100% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
}

.join-button:hover {
  animation: wiggle 0.2s ease-in-out infinite;
}