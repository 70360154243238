* {
  font-family: 'Happy Monkey', sans-serif;
}

.tokenomics {
  text-align: center;
  padding: 100px;
  background-color: #F2DBAE; /* Light background for the section */
}

.tokenomics h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.tokenomics p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.token-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
}

.token-detail {
  width: 222px;
}

@media (max-width: 768px) {
  .token-detail {
    width: 100%;
  }
}

.token-detail h3 {
  font-size: 1.5rem;
  color: #2f2fb9; /* Thematic color */
}

.token-detail p {
  font-size: 1rem;
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
  .token-details {
    flex-direction: column;
  }
}
