* {
  font-family: 'Happy Monkey', sans-serif;
}

@keyframes wiggle {
  0%, 100% { transform: rotate(-2deg); }
  50% { transform: rotate(2deg); }
}

.trade-interface {
  text-align: center;
  padding: 20px;
  background-color: #F2DBAE;
}

.trade-interface h2 {
  margin-bottom: 20px;
}

.exchanges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.exchange {
  background-color: #D98943;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px #260101;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 200px; /* Adjust as needed */
  cursor: pointer;
  }

.exchange:hover {
  animation: wiggle 0.5s ease-in-out infinite;
}
  
  .exchange-logo {
  width: 100px; /* Adjust as needed */
  height: auto;
  margin-bottom: 10px;
  }
  
  .exchange p {
  color: #BF3030;
  text-decoration: none;
  font-size: 1rem; /* Adjust font size as needed /
  margin-top: 10px; / Adds space between the logo and text */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
  .exchanges {
  flex-direction: column;
  }
  
  .exchange {
  width: auto; /* Take full width on smaller screens */
  }
  }